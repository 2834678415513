import { GET,POST,PUT } from '../utils/http'
let url = 'https://operation.lejian.com/insurance/' // uat地址
let url1 = 'https://operation.lejian.com/appointment/'  //用户信息

//获取用户信息
export const getUser = () => {
    return GET(`${url1}users/msg`)
}
//保障方案
export const supportConceptDetail = () => {
    return GET(`${url}policy/current`)
}

//理赔单保存
export const saveClaims = (params) => {
    return POST(`${url}claims/save`,params)
}

//理赔单列表
export const claimsList = () => {
    return GET(`${url}claims/list/current`)
}

//理赔单详情
export const claimsDetail = (claimsId) => {
    return GET(`${url}/claims/detail/${claimsId}`)
}

//信息完善
export const saveUser = (params) => {
    return PUT(`${url}policy/one`,params)
}






