// import Vue from 'vue'
import axios from 'axios'
// import { from } from 'core-js/fn/array'
import {Toast} from 'vant'
// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('user-token')) {
      config.headers['user-token'] = localStorage.getItem('user-token')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

// http response 拦截器
axios.interceptors.response.use(
  response => {
    Toast.clear();
    if(response.data.success){
      return response.data
    }else{
      Toast(response.data.message);
    }
  },
  error => {
    console.log(error.response)
    Toast.clear();
    return Promise.reject(error)
  })


//get请求
export const GET = (url, param) => {
  if (param) {
    return axios.get(url, {
      params: param
    })
  } else {
    return axios.get(url)
  }
}
//post请求
export const POST = (url, param, config) => {
  if (config) {
    return axios.post(url, param, config)
  } else {
    return axios.post(url, param)
  }
}

export const PUT = (url, param) => {
  return axios.put(url, param)
}

export const DELETE = (url) => {
  return axios.delete(url)
}
